import {AppBar, Toolbar, Typography, Button, Box} from '@mui/material';

import { SITE_NAME } from '../const';

import {Link} from 'react-router-dom';

function MyHeader() {
    return(
        <AppBar position="static">
        <Toolbar>
            <Link to="/" style={{textDecoration: "none", color:"inherit"}}>
                <Typography variant="h5" >
                    {SITE_NAME}
                </Typography>
            </Link>

            <Typography variant="subtitle2" sx={{ml:2}}>
                AI生成のフリー素材
            </Typography>

            <Box sx={{flexGrow:1}}></Box>

            <Link to="/about" style={{textDecoration: "none", color:"inherit"}}>
                <Button color="inherit">About</Button>
            </Link>
        </Toolbar>
        </AppBar>
    )
}

export default MyHeader;