import { useState, useEffect } from 'react';
import { LAMBDA_LIST_API, ADSENSE_CLIENT, ADSENSE_SQUARE_DISPLAY_SLOT } from '../const';
import Grid from '@mui/material/Unstable_Grid2'; 
import {CircularProgress,
    Pagination,
    Container} from '@mui/material';
import  IllustCard  from '../Components/IllustCard';
import { IllustACCard,IllustASCard } from '../Components/IllustCard';
import {Helmet} from 'react-helmet';
import Adsense from 'react-adsense';
import MyHeader from '../Components/MyHeader';
import SearchBar from '../Parts/searchBar'
import GA4 from 'react-ga4';

const ITEMS_PER_PAGE = 44

function set_ids(data){
    return(
        data.map(function(item,index){ // set ids
            item.id = item.page_id
            return(item)
        })
    )
}

function set_search_key(data){
    return(
        data.map(function(item,index){ // set ids
            item.search_key = item.tags.SS.join(",")
            item.search_key += "," + item.name.S
            return(item)
        })
    )
}

function IllustList(props){
    const [display_items, setDisplayItems] = useState([]); // [item, setItem
    const [illusts, setIllusts] = useState([]);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    useEffect(() => {
        // fetch data from API
        fetch(LAMBDA_LIST_API)
        .then(res => res.json())
        .then(data => {
            var tmp = set_ids(data.items)
            tmp = set_search_key(tmp)
            setIllusts(tmp)
            setSearchResult(tmp)
        })
    }, []);



    const Items = () => {

        useEffect(() => {
            const newItems = []

            if (illusts.length === 0){
                newItems.push(
                    <Grid xs={12}>
                        <CircularProgress></CircularProgress>
                    </Grid>
                )
            }else if(searchResult.length === 0){
                newItems.push(
                    <Grid xs={12}>
                        <p>検索結果がありません</p>
                    </Grid>
                )
            }else{
                const totalPage = Math.ceil(searchResult.length / ITEMS_PER_PAGE)

                Array.from({length: ITEMS_PER_PAGE}).forEach((_, index) => {
                    const item = searchResult[(page - 1) * ITEMS_PER_PAGE + index]
                    if (item) {
                        newItems.push(
                            <Grid key={item.id.S}
                                xs = {12}  sm = {6} lg = {4} xl = {3}
                            >
                                <IllustCard illust={item} />

                            </Grid>           
                        );
                    }
                })

                if (newItems.length > 5){
                    
                    newItems.splice(4,0, 
                        <Grid key="ac-1"
                            xs = {12}  sm = {6} lg = {4} xl = {3}
                        >
                            <IllustACCard id="23766208" thumbnail_url="https://thumb.ac-illust.com/9b/9b2abed5895964f0f604c3519ec9408e_w.jpeg" />
                        </Grid>
                    )

                    newItems.splice(10,0,
                        <Grid key="as-1"
                            xs = {12}  sm = {6} lg = {4} xl = {3}
                        >
                            <IllustASCard id="555055173" thumbnail_url="https://as1.ftcdn.net/v2/jpg/05/57/64/64/1000_F_557646444_okHvvsEZIn7J0xNAFiICwnirYJur1uxb.jpg"></IllustASCard>
                        </Grid>
                    )


                    newItems.splice(16,0, 
                        <Grid key="ac-2"
                            xs = {12}  sm = {6} lg = {4} xl = {3}
                        >
                            <IllustACCard id="23797118" thumbnail_url="https://thumb.ac-illust.com/92/921b47b86be2539134c5a30f9c8ace71_w.jpeg" />
                        </Grid>
                    )

                    newItems.splice(22,0, 
                        <Grid key="ac-3"
                            xs = {12}  sm = {6} lg = {4} xl = {3}
                        >
                            <IllustACCard id="23779189" thumbnail_url="https://thumb.ac-illust.com/a3/a3f1ce5aac698363920f4b997497e534_w.jpeg" />
                        </Grid>
                    )
                }
            }
            setDisplayItems(newItems)

      }, [page, searchResult,illusts])

        return display_items;
      };


    const Helm = () => {
        return(
            <Helmet>
                <meta property="og:title" content="ΑImpactArt" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://aimpact.nekast.com/" />
                <meta property="og:image" content="https://aimpact.nekast.com/ogp.png" />
                <script type="text/javascript">
                  {`window.prerenderReady = true`}
                </script>
            </Helmet>
        )
    }

    const PageNav = () => {
        if (searchResult.length === 0) return null
        return(
            <Grid container
                justifyContent="center"
                alignItems="center"
                sx={{mt:2}}
            >
                <Pagination
                    count={Math.ceil(searchResult.length / ITEMS_PER_PAGE)}
                    page={page}
                    onChange={(event, value) => {
                        setPage(value);
                        event.stopPropagation();
                    }}
                />
            </Grid>
        )
    }

    const search = () =>{
        setDisplayItems([])
        if(!query){
            setSearchResult(illusts)
        }else{
            setSearchResult(illusts.filter((item) => {
                return item.search_key.includes(query)
            }))
        }
        setPage(1)
    }

    const handleSearch = (event) => {
        event.preventDefault();
        GA4.event({
            category: 'illust',
            action: 'search',
            label: query
        })
        search(query)
    }

    const updateQuery = (event) => {
        setQuery(event.target.value)
       
    }
            

    return (
        <div>
            <Helm></Helm>
            <MyHeader></MyHeader>

            <Container maxWidth="md" sx={{
                    marginTop: 2,
                    marginBottom: 2,
            }}>
                <SearchBar 
                    onSubmit={handleSearch}
                    onChange={updateQuery}></SearchBar>
            </Container>
            
            <Grid container 
                spacing={2}
            > 
                {Items()}
            </Grid> 
    
            <PageNav></PageNav>
        </div>
        );

}

export default IllustList;