import {Button,CircularProgress, Box} from '@mui/material';
import { LAMBDA_DOWNLOAD_API } from '../const';
import {useState,useEffect} from 'react';

import GA4 from 'react-ga4'

function DownloadButton(props){
    const [loading, setLoading] = useState(false)


    const download = (id)=>{
        if (loading) return
        setLoading(true)
        fetch(`${LAMBDA_DOWNLOAD_API}?id=${id}`)
        .then(res => res.json())
        .then(url => downloadImage(url,setLoading))
        .then(() => {
            GA4.event({
                category: 'illust',
                action: 'download',
                label: id,
                value: 1,
              })
        })
    }

    const Progress = () => {
        if (!loading) return null
        return(
            <CircularProgress size={24}/>
        )
    }

    return(
        <Box>
            <Button 
                variant="contained"
                onClick={() => download(props.id)}
                disabled={loading}
            >
                ダウンロード
            </Button>
            <Progress></Progress>
        </Box>

    )
}



function downloadImage(url,setLoading=null) {
    // 新しいImageオブジェクトを作成します
    const img = new Image();
    // crossOrigin属性を設定して、画像のダウンロードを許可します
    img.crossOrigin = "anonymous";
  
    // 画像の読み込みが完了した時に呼び出されるコールバック関数を設定します
    img.onload = function() {
      // canvasを作成し、画像を描画します
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = this.width;
      canvas.height = this.height;
      context.drawImage(this, 0, 0);
  
      // canvasからデータURLを作成します
      const dataURL = canvas.toDataURL("image/png");
  
      // aタグを作成し、ダウンロードリンクを設定します
      const link = document.createElement("a");
      link.download = "image.png";
      link.href = dataURL;
  
      // リンクをクリックしてダウンロードを開始します
      link.click();
    
      if(setLoading) setLoading(false)
    };
  
    // 画像の読み込みを開始します
    img.src = url;
  }
  

export default DownloadButton;