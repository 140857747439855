import { Chip } from '@mui/material';

function Tag(props){
    const tag = props.tag
    return (
        <Chip label={tag}/>
    )
}

function Tags(props){
    const tags = props.tags

    return (
        <div>
          {tags.map((tag) => (
            <Tag tag={tag} key={tag}/>
          ))}
        </div>
    );
}

export {Tag as Tag};
export default Tags;
