import { AWS_REGION } from "../const"
import {Card, CardMedia, Modal, Box, Button, Chip} from "@mui/material"
import AspectRatio from '@mui/joy/AspectRatio';
import {Link} from "react-router-dom"
import { useState } from "react";
import Info from "../Components/Info"
import GA4 from 'react-ga4'

const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    // border: '0px solid #000',
    boxShadow: 24,
    p: 4,
  };

function thumbnail_url(key){
    const apName = "aimpact-art"
    return `https://${apName}.s3.${AWS_REGION}.amazonaws.com/${key}`

}

function IllustCard(props){
    const [open, setOpen] = useState(false);

    const InfoModal = () => {
        return (
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modal_style}>
                    <Info data={props.illust}></Info>
                    <Button onClick={() => setOpen(false)} sx={{mt: 2}}>閉じる</Button>
                </Box>
            </Modal>
        )
    }

    const handle_click = (event) => {
        event.stopPropagation()
        setOpen(true)
        GA4.event({
            category: 'illust',
            action: 'illust_open',
            label: props.illust.id.S,
        })
    }

    return(
        <div onClick={(event)=> event.stopPropagation()}>
            <Card sx={{  }}  onClick={handle_click}>
                <AspectRatio objectFit="contain" color="secondary">
                    <img 
                        src={thumbnail_url(props.illust.thumbnail.S)}
                        loading="lazy"
                        />
                </AspectRatio>

            </Card>

            <InfoModal></InfoModal>
        </div>
        // </Link>
    )
}

function IllustACCard(props){
    const thumbnail_url = props.thumbnail_url
    const affiliate_url = `https://www.ac-illust.com/main/detail.php?id=${props.id}#a_aid=6461c732539a5&a_cid=b7c162f5`
    const handle_click = (event) => {
        event.stopPropagation()
        GA4.event({
            category: 'illust',
            action: 'ac_click',
            label: props.id
        })
        window.open(affiliate_url, '_blank')
    }
    return(
        <Card onClick={handle_click}
            sx={{
                position: "relative",
                "&:hover": {
                    cursor: "pointer",
                },
            }}
        >
            <AspectRatio objectFit="contain" color="secondary"
            >
                <img 
                    src={thumbnail_url}
                    loading="lazy"
                    />
            </AspectRatio>
            <Chip 
                label="イラストAC" 
                size="small"
                color="warning"
                variant="filled"
                sx = {{
                    position: "absolute",
                    top: "10px",
                    left: "10px",

                }}></Chip>
        </Card>
    )
}


function IllustASCard(props){
    const thumbnail_url = props.thumbnail_url
    const affiliate_url = `https://stock.adobe.com/jp/images/%E3%82%A2%E3%82%A4%E3%83%89%E3%83%AB%E8%A1%A3%E8%A3%85/557646444?asset_id=${props.id}`
    const handle_click = (event) => {
        event.stopPropagation()
        GA4.event({
            category: 'illust',
            action: 'as_click',
            label: props.id
        })
        window.open(affiliate_url, '_blank')
    }
    return(
        <Card onClick={handle_click}
            sx={{
                position: "relative",
                "&:hover": {
                    cursor: "pointer",
                },
            }}
        >
            <AspectRatio objectFit="contain" color="secondary"
            >
                <img 
                    src={thumbnail_url}
                    loading="lazy"
                    />
            </AspectRatio>
            <Chip 
                label="Adobe Stock" 
                size="small"
                color="primary"
                variant="filled"
                sx = {{
                    position: "absolute",
                    top: "10px",
                    left: "10px",

                }}></Chip>
        </Card>
    )
}

export default IllustCard;
export {thumbnail_url}
export {IllustACCard}
export {IllustASCard}