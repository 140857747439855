import React from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@mui/material';
import { Description, Policy } from '@mui/icons-material';
import {Link} from 'react-router-dom';
import MyHeader from '../Components/MyHeader';

const About = () => {
  
  return (
    <div>
        <MyHeader></MyHeader>
        <Container maxWidth="md" sx={{
            marginTop: 4,
            marginBottom: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
        <Typography variant="h4" component="h1" gutterBottom>
            サイトについて
        </Typography>
        <Typography variant="body1" paragraph>
            当サイトは、AIによって生成されたフリーのストック画像を提供しています。<br></br>
            これらの画像は、個人的な目的にも商用利用にも自由にご利用いただけます。
        </Typography>
        <Typography variant="body1" paragraph>
            ただし、以下の利用に関しては厳禁とさせていただきますので、あらかじめご了承ください。
        </Typography>
        <List>
            <ListItem>
            <Policy sx={{
                    marginRight: 1,
                    fontSize: 'inherit',
            }} />
            <ListItemText
                primary="公序良俗に反する利用"
            />
            </ListItem>
            <ListItem>
            <Policy sx={{
                    marginRight: 1,
                    fontSize: 'inherit',
            }} />
            <ListItemText
                primary="素材のイメージを損なうような攻撃的、差別的、性的、過激な利用"
            />
            </ListItem>
            <ListItem>
            <Policy sx={{
                    marginRight: 1,
                    fontSize: 'inherit',
            }} />
            <ListItemText
                primary="反社会的勢力や違法行為に関わる利用"
            />
            </ListItem>
        </List>
        <Typography variant="body1" paragraph>
            これらの条件に同意される場合は、画像をご利用いただけます。<br></br>
            ご理解とご協力のほど、よろしくお願いいたします。
        </Typography>
        
        <Link to="/privacy" underline="none">
            <Button>プライバシーポリシー</Button>
            </Link>
        </Container>
                
    </div>
    );
  };

export default About;
