import {TextField, IconButton, AppBar, Toolbar} from '@mui/material';
import SearchIcon from "@mui/icons-material/Search"

function SearchBar(props) {
  return (
    <form onSubmit={props.onSubmit}>
         <div style={{ display: 'flex' }}>
            <TextField
                label="検索"
                variant="outlined"
                fullWidth
                onChange={props.onChange}
                />
            <IconButton type="submit">
                <SearchIcon />
            </IconButton>
        </div>
    </form>
  );

}

export default SearchBar;