import Grid from '@mui/material/Unstable_Grid2'; 
import AspectRatio from '@mui/joy/AspectRatio';
import { Box, CircularProgress } from '@mui/material';
import { thumbnail_url } from './IllustCard';
import DownloadButton from '../Parts/DownloadButton';
import Tags from '../Parts/Tags';
import ShareButton from '../Parts/Shares';
import {Link} from 'react-router-dom';

function Info(props){
    if (props.data.length === 0){
        return (<CircularProgress></CircularProgress>)
    }
    if(props.data.error){
        return (<p>Not found</p>)
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} >
                <Box textAlign={"left"} sx={{m:"1rem"}}>
                    <h2>{props.data.name.S}</h2>
                </Box>
            </Grid>

            <Grid item xs={12} md={6}>
                <Link to={"/detail/"+props.data.page_id.S}>
                    <AspectRatio objectFit="contain">
                        <img src={thumbnail_url(props.data.thumbnail.S)}/>
                    </AspectRatio>
                </Link>
            </Grid>

            <Grid item xs={12} md={6}>
                <DownloadButton id={props.data.page_id.S}></DownloadButton>
                <Box textAlign={"left"} sx={{m:"1rem"}}>
                    <p>サイズ: {props.data.width.N} x {props.data.height.N}</p>
                </Box>
                <Box textAlign={"left"} sx={{m:"1rem"}}>
                    <Tags tags={props.data.tags.SS}></Tags>
                </Box>
                <Box textAlign={"left"} sx={{m:"1rem"}}>
                    <ShareButton 
                        id={props.data.page_id.S} 
                        title={props.data.name.S}
                        thumbnail={thumbnail_url(props.data.thumbnail.S)}
                    ></ShareButton>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Info;