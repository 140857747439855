import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MyHeader from '../Components/MyHeader';

const PrivacyPolicy = () => {
  return (
    <div>
      <MyHeader></MyHeader>
      <Container maxWidth="md" sx={{
                    marginTop: 4,
      }}>
      <Typography variant="h2" gutterBottom>
        プライバシーポリシー
      </Typography>
      <Typography variant="body1" gutterBottom align="left">
        このウェブサイトでは、Adsense、Google Analytics、Amazonアソシエイトなどのサービスを利用しています。<br></br>
        これらのサービスを使用することにより、ユーザーから以下のデータが収集される場合があります。<br></br>
        ・IPアドレス<br></br>
        ・ブラウザの種類およびバージョン<br></br>
        ・アクセスしたページのURL<br></br>
        ・訪問時間<br></br>
      </Typography>
      <br></br>
      <Typography variant="body1" gutterBottom align="left">
        収集されたデータは以下の目的で使用されます。<br></br>
        ・サイトのトラフィック解析および改善<br></br>
        ・広告の配信およびパーソナライズ<br></br>
        ・製品の販売およびアフィリエイトマーケティング<br></br>
      </Typography>
      <br></br>
      <Typography variant="body1" gutterBottom align="left">
        また、クッキーを使用してユーザーの情報を収集する場合があります。<br></br>
        クッキーは、訪問者がサイトを再訪問した場合に、再び情報を入力する必要がないようにするために使用されます。<br></br>
        ユーザーは、ブラウザの設定を変更することでクッキーを無効にすることができます。<br></br>
      </Typography>
      <br></br>
      <Typography variant="body1" gutterBottom align="left">
        収集されたデータは、当社の社員やサービス提供者など、当社と契約を結んでいる第三者に提供される場合があります。<br></br>
        また、法的な要請に基づき、当社は収集されたデータを第三者と共有することがあります。<br></br>
        ただし、個人を特定する情報は開示されないことがあります。<br></br>
      </Typography>
      {/* <Typography variant="body1" gutterBottom>
        このプライバシーポリシーについてのご質問やご要望がございましたら、お気軽にお問い合わせください。
      </Typography> */}
</Container>
    </div>

);
};

export default PrivacyPolicy;
