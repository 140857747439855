import logo from './logo.svg';
import './App.css';

import GA4 from 'react-ga4';

import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';
import IllustList from './Pages/IllustList';
import Detail from './Pages/Detail';
import About from './Pages/About';
import PrivacyPolicy from './Pages/PrivacyPolicy';

import MyHeader from './Components/MyHeader';

GA4.initialize("G-JVRKDCCERL")

function App() {
  return (
    <div className="App">
      {/* <MyHeader></MyHeader> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IllustList/>} />
          <Route path="/detail/:id" element={<Detail></Detail>} />
          <Route path="/about" element={<About></About>} />
          <Route path="/privacy" element={<PrivacyPolicy></PrivacyPolicy>} />
          <Route path="*" element={
            <div>
              <MyHeader></MyHeader>
              <br></br>
              Not Found<br></br>
              <br></br>
              <Link to="/">トップページへ</Link>
              </div>
            }/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
