import Info from '../Components/Info';
import {useParams} from 'react-router-dom';
import {LAMBDA_GET_API, HOME_URL}  from '../const';
import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {thumbnail_url} from '../Components/IllustCard'
import MyHeader from '../Components/MyHeader';

function Detail(props){
    const params = useParams()

    const [illust, setIllust] = useState([]);
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        // fetch data from API
        fetch(`${LAMBDA_GET_API}?id=${params.id}`)
        .then(res => res.json())
        .then(data => {
            setLoading(false)
            setIllust(data)
        })
    }, []);

    const Helm = () => {
        if(loading) return(
            <Helmet>
                <script type="text/javascript">
                  {`window.prerenderReady = false`}
                </script>
            </Helmet>
        )        
        if (illust.error) return null
        return(
            <Helmet>
                <title>{illust.name.S}</title>
                <meta property="og:title" content={illust.name.S} />
                <meta property="og:image" content={thumbnail_url(illust.thumbnail.S)} />
                <meta property="og:url" content={`${HOME_URL}/detail/${illust.page_id.S}`} />
                <meta property="og:type" content="article" />
                <script type="text/javascript">
                  {`window.prerenderReady = true`}
                </script>
            </Helmet>
        )
    }

    return (
        <div>
            <Helm></Helm>
            <MyHeader></MyHeader>
            <Info data={illust}></Info>
        </div>
    )
}

export default Detail;