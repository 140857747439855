import IconButton from '@mui/joy/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import HatenaBookmarkIcon from '@mui/icons-material/Bookmark';
import ShareIcon from '@mui/icons-material/Share';
import { HOME_URL,SITE_NAME } from '../const';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function shareText(title){
    if (title === ""){
        return SITE_NAME
    }
    return `${SITE_NAME} - ${title}`
}

function shareURL(id){
    return `${HOME_URL}detail/${id}`
}

const TwitterShareButton = (props) => {
  const shareUrl = props.url
  const text = props.text

  const handleClick = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(text)}`);
  };

  return (
    <IconButton onClick={handleClick}>
      <TwitterIcon />
    </IconButton>
  );
};

const PinterestShareButton = (props) => {
  const shareUrl = shareURL(props.url);
  const mediaUrl = props.thumbnail;
  const description = shareText(props.text);

  const handleClick = () => {
    window.open(`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(shareUrl)}&media=${encodeURIComponent(mediaUrl)}&description=${encodeURIComponent(description)}`);
  };

  return (
    <IconButton onClick={handleClick}>
      <PinterestIcon />
    </IconButton>
  );
};


const HatenaBookmarkShareButton = (props) => {
  const shareUrl = props.url;
  const title = props.text;

  const handleClick = () => {
    window.open(`https://b.hatena.ne.jp/add?mode=confirm&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(title)}`);
  };

  return (
    <IconButton onClick={handleClick}>
      <HatenaBookmarkIcon />
    </IconButton>
  );
};

const LinkShareButton = (props) => {
  const url = shareURL(props.id)
  return(
    <CopyToClipboard text={url} onCopy={() => alert("リンクがクリックボードにコピーされました")}>
      <IconButton>
        <ShareIcon />
      </IconButton>
    </CopyToClipboard>
  )
}

const ShareButtons = (props) => {
  const url = shareURL(props.id)
  const text = shareText(props.title ? props.title : "")
  const thumbnail = props.thumbnail
  return (
    <div>
      <TwitterShareButton url={url} text={text}/>
      <PinterestShareButton url={url} text={text} thumbnail={thumbnail}/>
      <HatenaBookmarkShareButton url={url} text={text}/>
      <LinkShareButton id={props.id}/>
    </div>
  );
}

export {TwitterShareButton as TwitterShareButton}
export {PinterestShareButton as PinterestShareButton}
export {HatenaBookmarkShareButton as HatenaBookmarkShareButton}

export default ShareButtons;
